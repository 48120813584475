import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    width: 20,
    height: 14,
    position: 'relative',
    margin: 0,
    transform: 'rotate(0deg)',
    transition: '.5s ease-in-out',
    cursor: 'pointer',
    '& > span': {
      display: 'block',
      position: 'absolute',
      height: 2,
      width: '100%',
      background: '#000',
      opacity: 1,
      left: 0,
      transform: 'rotate(0deg)',
      transition: '.25s ease-in-out',
    },
    '& span:nth-child(1)': {
      top: 0,
    },
    '& span:nth-child(2), & span:nth-child(3)': {
      top: 6,
    },
    '& span:nth-child(4)': {
      top: 12,
    },
  },
  isOpen: {
    '& span:nth-child(1)': {
      top: 6,
      width: '0%',
      left: '50%',
    },
    '& span:nth-child(2)': {
      transform: 'rotate(45deg)',
    },
    '& span:nth-child(3)': {
      transform: 'rotate(-45deg)',
    },
    '& span:nth-child(4)': {
      top: 6,
      width: '0%',
      left: '50%',
    },
  },
})