import { useStyles } from './styles'
import clsx from 'clsx'

const IconBurgerAnimated = ({ isOpen = false, onClick }: {isOpen?: boolean, onClick: () => void}) => {
  const cls = useStyles()

  return (
    <div onClick={onClick} className={clsx(cls.root, isOpen && cls.isOpen)}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default IconBurgerAnimated